import React, { useEffect } from 'react';
import './css/style.scss';
import 'aos/dist/aos.css'
import AOS from 'aos';

import Home from './pages/Home';



function App() {

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-in-out-sine',
    });
  }, []);

  return (
    <>
          <Home />
    </>
  );
}

export default App;
