import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import ReactPlayer from "react-player";

import myVideo1 from "../images/geo.mp4"
import myVideo2 from "../images/geo2.mp4"
import myVideo3 from "../images/geo1.mp4"
import myVideo4 from "../images/geoo4.mp4"

function Features() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <section className="relative bg-black  scroll-mt-6 snap-y ">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 bg-black pointer-events-none mb-16" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-theme-800 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 snap-start">
            <h1 className="h2 text-2xl mb-4 text-theme-1000 tracking-widest">EXPLORE</h1>
            {/*<p className="text-xl text-gray-600">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.</p>*/}
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6 snap-start">

            {/* Content */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6 snap-start " data-aos="fade-right">
              <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 className="h3 text-2xl mb-3 text-theme-900 tracking-widest" data-aos="flip-right" data-aos-delay="400">Algorithmically rendered geometric art</h3>
                <p className="text-xl text-theme-800">Each piece is a completely unique blend of human and machine creative output. <br /><br />Check out a few exmaples:</p>
              </div>
              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0 space-y-5" >
              <div data-aos="flip-left" data-aos-delay="400">
                <a
                  className={`bg-black hover:bg-theme-300 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 1 ? ' shadow-md border-theme-700 hover:border-theme-1000' : 'border-theme-900 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(1); }}
                  // data-aos="flip-left" data-aos-delay="400"
                >
                <div className="flex flex-col justify-between w-full">
                    <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-900">The Heart of it All</div>
                    <div className="text-theme-800 leading-snug tracking-tight mt-2">Evolution <strong>Phase 4</strong></div>
                  </div>
                  <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 1 ? 'ease-in duration-300 bg-theme-900 hover:bg-theme-1000 hover:shadow-lg' : 'bg-theme-800 bg-transparent'}`}>
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                    </svg>
                  </div>
                </a>
                </div>

                <div data-aos="flip-right" data-aos-delay="400">
                <a
                  className={`bg-black hover:bg-theme-300 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 2 ? 'border-theme-700 hover:border-theme-1000 hover:shadow-lg' : 'border-theme-900 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(2); }}
                  // data-aos="flip-right" data-aos-delay="400"
                >
                  <div className="flex flex-col justify-between w-full">
                    <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-900">The Circle Squared</div>
                    <div className="text-theme-800 leading-snug tracking-tight mt-2">Evolution <strong>Phase 3</strong></div>
                  </div>
                  <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 2 ? 'bg-theme-900 hover:bg-theme-1000 hover:shadow-lg' : 'bg-theme-800 bg-transparent'}`}>
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" >
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fillRule="nonzero" />
                    </svg>
                  </div>
                </a>
                </div>

                <div data-aos="flip-left" data-aos-delay="400">
                <a
                  className={` bg-black hover:bg-theme-300 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 3 ? 'border-theme-700 hover:border-theme-1000 hover:shadow-lg' : 'border-theme-900 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(3); }}
                  // data-aos="flip-right" data-aos-delay="400"
                >
                <div className="flex flex-col justify-between w-full">
                    <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-900 snap-start">Blossom</div>
                    <div className="text-theme-800 leading-snug tracking-tight mt-2">Evolution <strong>Phase 2</strong></div>
                  </div>
                  <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 3 ? 'bg-theme-900 hover:bg-theme-1000 hover:shadow-lg' : 'bg-theme-800 bg-transparent'}`}>
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fillRule="nonzero" />
                    </svg>
                  </div>
                </a>
                  </div>

                <div data-aos="flip-right" data-aos-delay="400">
                <a
                  className={` bg-black hover:bg-theme-300 flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${tab !== 4 ? 'border-theme-700 hover:border-theme-1000 hover:shadow-lg' : 'border-theme-900 border-transparent'}`}
                  href="#0"
                  onClick={(e) => { e.preventDefault(); setTab(4); }}
                >
                <div className="flex flex-col justify-between w-full">
                    <div className="font-bold mb-1 leading-tighter tracking-widest text-theme-900">Birth of Rods</div>
                    <div className="text-theme-800 leading-snug tracking-tight mt-2">Evolution <strong>Phase 1</strong></div>
                  </div>
                  <div className={`flex justify-center items-center w-8 h-8  rounded-full shadow flex-shrink-0 ml-3 ${tab !== 4 ? 'bg-theme-900 hover:bg-theme-1000 hover:shadow-lg' : 'bg-theme-800 bg-transparent'}`}>
                  <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                </svg>
                  </div>
                </a>
                </div>
              </div>
            </div>

            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 bg-black h-auto" data-aos="zoom-y-out" ref={tabs}>
              <div className="relative flex flex-col text-center m-auto   w-full lg:text-right bg-black pb-56">
                {/* Item 1 */}
                <Transition
                  show={tab === 1}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col w-full overflow-x-hidden">
                  <div className='player-wrapper m-auto  transform -translate-x-1/2 right-1/2 z-50 '>
                    <ReactPlayer
                    loop={true}
                    playing={true}
                    muted={true}
                      url={myVideo3}
                      // ...
                    style={{
                      width: "auto", // Reset width
                      height: "auto",
                      margin: "0 auto",
                      transform: "translate( 30%)",
                    }}/>
                  </div>
                  </div>
                </Transition>
                {/* Item 2 */}
                <Transition
                  show={tab === 2}
                  appear={true}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                <div className="relative inline-flex flex-col w-full overflow-x-hidden">
                <div className='player-wrapper m-auto  transform -translate-x-1/2 right-1/2 z-50 '>
                  <ReactPlayer
                  loop={true}
                  playing={true}
                  muted={true}
                    url={myVideo4}
                    style={{
                      width: "auto", // Reset width
                      height: "auto",
                      margin: "0 auto",
                      transform: "translate( 30%)",
                    }}/>
                </div>
                  </div>
                </Transition>
                {/* Item 3 */}
                <Transition
                show={tab === 3}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-700 transform order-first"
                enterStart="opacity-0 translate-y-16"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-300 transform absolute"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 -translate-y-16"
              >
                <div className="relative inline-flex flex-col w-full overflow-x-hidden">
                <div className='player-wrapper m-auto  transform -translate-x-1/2 right-1/2 z-50 '>
                  <ReactPlayer
                  loop={true}
                  playing={true}
                  muted={true}
                    url={myVideo2}
                    // ...
                  style={{
                    width: "auto", // Reset width
                    height: "auto",
                    margin: "0 auto",
                    transform: "translate( 30%)",
                  }}/>
                </div>
                </div>
              </Transition>
              <Transition
              show={tab === 4}
              appear={true}
              className="w-full"
              enter="transition ease-in-out duration-700 transform order-first"
              enterStart="opacity-0 translate-y-16"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-in-out duration-300 transform absolute"
              leaveStart="opacity-100 translate-y-0"
              leaveEnd="opacity-0 -translate-y-16"
            >
              <div className="relative inline-flex flex-col w-full overflow-x-hidden">
              <div className='player-wrapper m-auto  transform -translate-x-1/2 right-1/2 z-50 '>
                <ReactPlayer
                loop={true}
                playing={true}
                muted={true}
                  url={myVideo1}
                  // ...
                style={{
                  width: "auto", // Reset width
                  height: "auto",
                  margin: "0 auto",
                  transform: "translate( 30%)",
                }}/>
              </div>
              </div>
            </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
