import React from 'react';

import HeroHome from '../partials/HeroHome';
import FeaturesFAQ from '../partials/FeaturesFAQ';
import Features from '../partials/Features';
import Footer from '../partials/Footer';
import Timeline from '../partials/Timeline';

function Home() {

  return (
    <div className="flex flex-col min-h-screen">

      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow ">

        {/*  Page sections */}
        <HeroHome />
        <Features />
        <Timeline  />
        <FeaturesFAQ />

        </main>
        <Footer />
    </div>
  );
}

export default Home;