import React from 'react';

function FeaturesFAQ() {
  return (
    <section className="relative bg-black   ">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-black w-4/5 m-auto rounded-3xl pointer-events-none  " aria-hidden="true"></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-theme-300 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="py-12 md:py-20 border-t-2 border-theme-800 ">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 ">
            <h2 className="h2 mb-4 text-4xl  tracking-widest text-theme-600 hover:text-theme-1000">Geomatria FAQ</h2>
            <p className="text-xl text-theme-1000">Commonly asked questions, answers, thoughts etc{<br/>} Our vision is a collobarative community effort </p>
          </div>

          {/* Items */}

          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 border border-theme-500  hover:bg-theme-300 rounded shadow-xl " data-aos="flip-left" data-aos-delay="400">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-600 hover:text-theme-1000">What is this project?</h4>
              <p className=" text-theme-900 hover:text-theme-1000 text-center">First and foremost this is an Art project. After months of work and hundreds of hours, Ratio is ready to launch a completely unique 1/1 animiated geometric collection. Each piece is original, animated, hand reviewed for quality</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center p-6  border border-theme-500  hover:bg-theme-300 rounded shadow-xl" data-aos="flip-right" data-aos-delay="400">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-600 hover:text-theme-1000">Wen list?</h4>
              <p className="text-theme-900 hover:text-theme-1000 text-center">We will aim to list on MagicEden shortly after sell out, or other marketplaces (if the community supports it)</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center p-6  border border-theme-500  hover:bg-theme-300 rounded shadow-xl" data-aos="flip-left" data-aos-delay="400">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-600 hover:text-theme-1000">Wen mint?</h4>
              <p className="text-theme-900 hover:text-theme-1000 text-center">TBA soon! Keep notifications on twitter for further updates!</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center p-6 border border-theme-500  hover:bg-theme-300 rounded shadow-xl" data-aos="flip-right" data-aos-delay="400">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-600 hover:text-theme-1000">Why should I buy this?</h4>
              <p className="text-theme-900 hover:text-theme-1000 text-center">You support artistic creations on the solana blockchain! We plan to implement a series of upcomming events and announcements that only early holders will be able to access</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center p-6  border border-theme-500  hover:bg-theme-300 rounded shadow-xl" data-aos="flip-left" data-aos-delay="400">
            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-600 hover:text-theme-1000">Wen Whitelist?</h4>
            <p className="text-theme-900 hover:text-theme-1000 text-center">There will be no whitelist. While the current WL meta helps curb botting, it does not help our collective mental health :P </p>
          </div>



            {/* 6th item */}
            <div className="relative flex flex-col items-center p-6  border border-theme-500  hover:bg-theme-300 rounded shadow-xl" data-aos="flip-right" data-aos-delay="400">
             
            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 bg text-theme-600 hover:text-theme-1000">Mint Price? Supply?</h4>
            <p className="text-theme-900 hover:text-theme-1000 text-center">333 / 0.88 Sol</p>
          </div>
           
          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesFAQ;

