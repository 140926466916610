import React from 'react';
import Particles from "react-tsparticles";



function HeroHome() {
  const particlesInit = (main) => {
    console.log(main);
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }
  return (
    <section className="relative bg-black z-50 w-full">

      {/* Illustration behind hero content */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none w-full" aria-hidden="true">
 <svg width="auto" height="auto" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg" className='relative z-20'  >
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="# " offset="10%" />
              <stop stopColor="#9D0208" offset="77.402%" />
              <stop stopColor="#F48C06" offset="95%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>

          </svg>
        <Particles
        className="absolute top-0 left-0 w-full h-screen z-0"
        id="tsparticles"
        init={particlesInit}
        // loaded={particlesLoaded}
        options={{fpsLimit: 30,
          particles: {
            number: {
              value: 0,
              density: {
                enable: true,
                value_area: 800
              }
            },
            color: {
              value: "#FAA307",
              animation: {
                enable: true,
                speed: -70,
                sync: true
              }
            },
            shape: {
              type: "circle"
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: true,
                speed: 0.5,
                minimumValue: 0,
                sync: false
              }
            },
            size: {
              value: 4,
              random: { enable: true, minimumValue: 1 },
              animation: {
                enable: false,
                speed: 20,
                minimumValue: 4,
                sync: false
              }
            },
            life: {
              duration: {
                value: 3
              },
              count: 1
            },
            move: {
              angle: {
                value: 45,
                offset: 0
              },
              enable: true,
              gravity: {
                enable: true,
                acceleration: -0.5
              },
              speed: 10,
              direction: "top",
              random: false,
              straight: false,
              size: true,
              outModes: {
                default: "destroy",
                bottom: "none"
              },
              attract: {
                enable: false,
                distance: 300,
                rotate: {
                  x: 600,
                  y: 1200
                }
              }
            }
          },
         
          detectRetina: true,
          background: {
            color: "#000000"
          },
          emitters: {
            direction: "top",
            rate: {
              quantity: 15,
              delay: 0.01
            },
            size: {
              width: 500,
              height: 10
            },
            position: {
              x: 50,
              y: 100
            }
          }
        
        
        }}
      />

      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6  ">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20 items-center self-center">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16 relative z-50">
            <h1 className="sm:text-6xl text-3xl font-extrabold leading-tighter tracking-widest mb-4 text-theme-600 " data-aos="zoom-y-out" data-aos-delay="200">Geomatria</h1>
            <div className="max-w-3xl mx-auto">
              <p className="sm:text-xl text-lg text-theme-900 mb-8 tracking-widest " data-aos="zoom-y-out" data-aos-delay="150">333 Unique Geometric Creations - <br/><br/> Algorithms created within the Simulation of Reality</p>
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
               {/* <div>
                  <a className="btn text-white bg-theme-900 hover:bg-theme-1100 w-full mb-4 sm:w-auto sm:mb-0" onClick={()=>{alert("wallet draining in progress....")}}>Mint</a>
                </div>*/}
                </div>
              </div>
          </div>
          </div>
        </div>


    </section>
  );
}

export default HeroHome;