import React from 'react';
const Timeline = ( ) => {

    return (
        <div className='bg-black z-10 -mt-10'>
        <div className=" z-0 absolute border-theme-800 h-full border-2 "
data-aos="zoom-y-out" data-aos-delay="800"
style={{
    left: "50%"
}} />
<div className=' bg-black pt-40 '>

<div className="container bg-black mx-auto w-full ">

  <div className="relative wrap p-20 pt-64 ">
    <div className="max-w-3xl z-20 mx-auto text-center pb-12 md:pb-20 pt-20 border-b-2 border-t-2 border-theme-800 bg-black  ">
            <h2 className="h2 mb-4 sm:text-4xl text-2xl tracking-widest text-theme-600 hover:text-theme-1000">Geomatria Roadmap</h2>
            <p className="sm:text-xl text-sm text-theme-1000">Create innovative art, foster a positive, growing community of artists, hackers, and enthusiasts - lend your support to a massive NFT project.</p>
          </div>
    <div className=" absolute border-theme-800 h-full border-2 "
    data-aos="zoom-y-out" data-aos-delay="800"
    style={{
        left: "50%"
    }} />
  

<div className='flex flex-col'>
    {/* right timeline */}
    <div className=" hover:shadow-lg">
    <div className=" mb-56 pt-56 flex justify-between items-center w-full right-timeline" data-aos="zoom-y-out" data-aos-delay="300">
      <div className="order-1 w-3/12 sm:w-5/12"></div>
      <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800">
        <p className="mx-auto font-semibold text-lg text-black">1</p>
      </div>
      <div className="order-1 bg-black hover:bg-theme-300 transition duration-300 ease-in-out mb-3 border border-theme-700 z-50 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full p-8 ">
        <p className="mb-3 font-bold text-theme-900 text-xl leading-tighter tracking-widest">Vision👁️</p>
        <p className="text-sm leading-snug tracking-wide text-white text-opacity-100">We aim to generate long-term value for cryptoart fans, artists, and collectors. Our work draws on the history of generative art in this space and challenge it's conceptual limits.  Attracting 
        a network of artists, builders, collectors, and fans to bring together the cryptoart community.</p>
      </div>
    </div>
    {/* left timeline */}
    <div className="mb-56 flex justify-between flex-row-reverse items-center w-full left-timeline" data-aos="zoom-y-out" data-aos-delay="300">
      <div className="order-1 w-3/12 sm:w-5/12"></div>
      <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800">
        <p className="mx-auto text-black font-semibold text-lg">2</p>
      </div>
      <div className="order-1 bg-black hover:bg-theme-300 transition duration-300 ease-in-out mb-3 border  z-50 border-theme-700 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full p-8 ">
        <h3 className="mb-3 font-bold text-theme-900 text-xl leading-tighter tracking-widest">Build🛠️</h3>
        <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">We will gather our forces together, for only as a community can we continually build. Inside the <strong>Inner Sanctum</strong>, our tech and our art will speak for itself. We are still actively seeking bright and talented new artists looking for a launchpad in our community. Together we will shape the future.</p>
      </div>
    </div>
    </div>

    {/* right timeline */}
    <div className="mb-56 flex justify-between items-center w-full right-timeline" data-aos="zoom-y-out" data-aos-delay="300">
      <div className="order-1 w-3/12 sm:w-5/12"></div>
      <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800 ">
        <p className="mx-auto font-semibold text-lg text-black">3</p>
      </div>
      <div className="order-1 bg-black hover:bg-theme-300 transition duration-300 ease-in-out mb-3 border  z-50 border-theme-700 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full p-8 ">
        <h3 className="mb-3 font-bold text-theme-900 text-xl leading-tighter tracking-widest">Future🚀</h3>
        <p className="text-sm leading-snug tracking-wide text-white text-opacity-100">After integrating our DAO, we will gather community feedback are begin implementation on our vision. We will immediately invest in a marketing push, while we continually build a product of the highest caliber. Our DAO treasury will set online auctions for community sponsored pieces of art that will be used to raise funds, and to airdrop / distrubte them amoung the community.  </p>
      </div>
    </div>

    {/*left timeline */}
    <div className="mb-56 pb-56 flex justify-between flex-row-reverse items-center w-full left-timeline " data-aos="zoom-y-out" data-aos-delay="300">
      <div className="order-1 w-3/12 sm:w-5/12"></div>
      <div className="z-20 sm:flex hidden items-center order-1 bg-theme-1000 shadow-xl w-8 h-8 rounded-full border-4 border-theme-800">
        <p className="mx-auto text-black font-semibold text-lg">4</p>
      </div>
      <div className="order-1 bg-black hover:bg-theme-300 transition duration-300 ease-in-out mb-3 border  z-50 border-theme-700 hover:border-theme-1000 rounded-lg shadow-xl sm:w-5/12 sm:px-6 sm:py-4 w-full p-8 ">
        <h3 className="mb-3 font-bold text-theme-900 text-xl leading-tighter tracking-widest">Beyond🔮</h3>
        <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">Yes, we <i>will</i> implement staking, we <i>will</i> implment passive income, but lets look beyond these tired meta's into something beyond the pale. {<><br/> <br/></>} What will that be? :) </p>
      </div>
    </div>
    </div>
  </div>
</div>
</div>
</div>
)
}


export default Timeline
